export const termsData = `
<p>
<strong>Last Updated: March 31, 2024</strong>
</p>
<p>Please read these Terms of Service (the “<strong>Terms</strong>”) carefully because they govern your use of the website located at https://songbits.com (the “<strong>Site</strong>”), the platform on which users may purchase SongBits (the “<strong>SongBits Platform</strong>”) and the artist support platform accessible via the Site and corresponding mobile application (“<strong>App</strong>”) offered by SongBits Limited, a UK registered Limited Company, (“<strong>SongBits</strong>”). To make these Terms easier to read, the Site, our services and App are collectively called the “<strong>Services</strong>.”</p>
<p>
<strong>WHEN YOU AGREE TO THESE TERMS, YOU ARE AGREEING (WITH LIMITED EXCEPTION) TO RESOLVE ANY DISPUTE BETWEEN YOU AND SONGBITS THROUGH BINDING, INDIVIDUAL ARBITRATION RATHER THAN IN COURT. PLEASE REVIEW CAREFULLY SECTION 19 “DISPUTE RESOLUTION” BELOW FOR DETAILS REGARDING ARBITRATION. HOWEVER, IF YOU ARE A RESIDENT OF A JURISDICTION WHERE APPLICABLE LAW PROHIBITS ARBITRATION OF DISPUTES, THE AGREEMENT TO ARBITRATE IN SECTION 16 WILL NOT APPLY TO YOU BUT THE PROVISIONS OF SECTION 15 (GOVERNING LAW) WILL APPLY INSTEAD.</strong>
<strong>SONGBITS PURCHASED ON THE SONGBITS PLATFORM ARE NON-RETURNABLE.</strong>
</p>
<p>
<strong>Agreement to Terms.
</strong>By using our Services, you agree to be bound by these Terms. If you disagree to be bound by these Terms, do not use the Services. Notwithstanding the foregoing, the laws of some jurisdictions may limit or not permit certain provisions of this agreement, such as indemnification, the exclusion of certain warranties or the limitation of liability. In such a case, such provisions will apply only to the maximum extent permitted by the laws of such jurisdictions. Also, you may have additional legal rights in your jurisdiction, and nothing in these terms will prejudice your rights as a consumer of the Services under such applicable law.</p>
<p>
<strong>Additional Terms and Policies.
</strong>Your use of the Services is subject to additional terms and policies as may be posted on the Services from time to time, and that will include the following</p>
<p>
<strong>Privacy Policy.
</strong>Please review our Privacy Policy, which also governs your use of the Services, for information on how we collect, use and share your information.</p>
<p>
<strong>Artist Terms and Purchaser Terms</strong>. If you are an Artist or a Fan (as each is defined below), you will be subject to the Artist Terms (as defined below) or Fan Terms, as applicable.</p>
<p>
<strong>SongBits Programs</strong>. Your participation in certain SongBits programs (e.g., promotions, contests, etc.) will be subject to additional terms and conditions specific to such programs.</p>
<p>
<strong>Changes to these Terms or the Services.
</strong>We may update the Terms from time to time at our sole discretion. If we do, we’ll let you know by posting the updated Terms on the Site to the App and/or may also send other communications. It’s important that you review the Terms whenever we update them, or you use the Services. If you continue to use the Services after we have posted updated Terms, you accept and agree to the changes. If you disagree to be bound by the changes, you may not use the Services anymore. Because our Services are evolving over time, we may change or discontinue all or any part of the Services, at any time and without notice, at our sole discretion.</p>
<p>
<strong>Who May Use the Services?
</strong>The Services are only available to users in certain jurisdictions who can use the Services as permitted under applicable law. The Services are not authorised for use in certain other jurisdictions where the United Kingdom has embargoed goods or has otherwise applied economic sanctions. Without limitation, you must be at least 18 years of age to use the Services. You may not attempt to access or use the Services if you are not permitted to do so.</p>
<p>
<strong>Account Creation</strong>. For certain features of the Services, you’ll need an account. To register an account, you must first successfully complete the sign-up process. It’s important that you provide us with accurate, complete and current account information and keep it up to date. We might have to suspend or terminate your account if you don't. Keep the account details and password confidential to protect your account, and notify us immediately of any unauthorised use. You’re responsible for all activities that occur under your account. You are only allowed to create one account and agree you won’t share your account with anyone. You also agree that you will not: (i) create another account if we’ve disabled one you previously established unless you have our prior written consent; (ii) buy, sell, rent or lease access to your account or username, unless you have our prior written consent; (iii) share your account password with anyone; or (iv) log in or try to log in to access the Service through unauthorised third-party applications or clients.</p>
<p>
<strong>Additional Information.
</strong>SongBits may require you to provide additional information and documents at the request of any competent authority or in case of application of any applicable law or regulation, including laws related to anti-laundering (legalisation) of incomes obtained by criminal means or for counteracting financing of terrorism. SongBits may also require you to provide additional information and documents in cases where it has reasons to believe that: (i) your account is being used for money laundering or any other illegal or unauthorised activity; (ii) you have concealed or reported false identification information and other details; or (iii) transactions effected via your account were effected in breach of these Terms. In each such case, SongBits, in its sole discretion, may disable (or terminate) your account until such additional information and documents are reviewed by SongBits and are accepted as satisfying the requirements of applicable law. If you do not provide complete and accurate information and documents in response to such a request, SongBits may refuse to provide the Services. We reserve the right to report any activity using the Services to relevant tax authorities as required under applicable law. You are solely responsible for maintaining all relevant Tax (as defined below) records and complying with any reporting requirements you may have related to our Services. You are further solely responsible for independently maintaining the accuracy of any record submitted to any tax authority, including any information derived from the Services.</p>
<p>
<strong>Digital Rewards.
</strong>You may receive SongBits or other digital assets in connection with your use of the Services. To receive digital rewards, you may have to connect to a third-party service or sign up for a solution provided by SongBits. Such digital assets may be subject to additional terms and conditions, which you will be deemed to accept upon your receipt of such digital assets.</p>
<p>
<strong>Right to Block Access.
</strong>We reserve the right, at any time, in our sole discretion, to block access to the Services from certain IP addresses and unique device identifiers.</p>
<p>
<strong>Equipment and Software.
</strong>You must provide all equipment, connectivity, and software necessary to connect to the Service. You are solely responsible for any fees incurred when accessing the Service, including Internet connection or mobile fees.</p>
<p>
<strong>About the Services.</strong>
</p>
<p>
<strong>Parties</strong>. Our Services also allow music professionals (“<strong>Artists</strong>”) to sell certain fractions of a song (each, a “<strong>SongBit</strong>”) to third-party entities or persons (“<strong>Fans</strong>”) via the SongBits Platform. SongBits is not a party to any transaction between Artists and any Fans. SongBits does not control or vet content provided by Artists, nor does SongBits assume any responsibility for the accuracy or reliability of any information provided by Artists.</p>
<p>
<strong>Our Role</strong>. You acknowledge and agree that the SongBit and any associated royalty payments shall be programmed to enable you to take certain actions to claim royalty payments. SongBits has no control or ability to direct such funds or obligation to collect or distribute such royalties to Artists or Fans.</p>
<p>
<strong>Our Relationship.
</strong>SONGBITS IS A PLATFORM. WE ARE NOT A BROKER, FINANCIAL INSTITUTION, OR CREDITOR. THE SERVICE IS AN ADMINISTRATIVE PLATFORM ONLY. SONGBITS FACILITATES TRANSACTIONS BETWEEN A BUYER AND SELLER BUT IS NOT A PARTY TO ANY AGREEMENT BETWEEN THE BUYER AND SELLER OF SONGBITS OR BETWEEN ANY USERS.</p>
<p>
<strong>Terms for Artists.</strong>
</p>
<ol>
<li>
  <strong>SongBits Fee</strong>. We may require a fee for using certain features of the Services (“<strong>SongBits Fee</strong>”). We reserve the right to change the SongBits Fee at our sole discretion. Any increase in the SongBits Fee will be communicated to you at least 14 days before it comes into effect.</li>
<li>
  <strong>Additional Obligations.
  </strong>By creating an account and selling SongBits, you will be subject to additional terms outlined in the Artist Terms and Conditions and a Memorandum of Understanding between you and SongBits (collectively, the “<strong>Artist Terms</strong>”).</li>
</ol>
<p>
<strong>Terms for Fans</strong>.</p>
<p>
<strong>SongBits Fee</strong>. Each Artist may require a fee to purchase one of its SongBits (“<strong>SongBits Fee</strong>”). As a Fan, you are responsible for paying the SongBits Fee according to any additional terms provided by such Artist in the Purchase Terms (defined below).</p>
<p>
<strong>Credit Card Transactions</strong>. You may purchase SongBits using a credit card. If you use a credit card to purchase a SongBit, you may need to claim any streaming royalties associated with your SongBit by occasionally following instructions on the Site. You expressly authorise us (and any payment processor) to charge your payment card for all purchases you make. For credit card payments, charge your credit card when you purchase a SongBit. The bank issuing your credit or card may control when to release funds in the case of an order cancellation or refund. We may ask you to supply additional information relevant to your transaction, including your credit card number, the expiration date of your credit card and your email and postal addresses for billing and notification (such information, “<strong>Payment Information</strong>”). You represent and warrant that you have the legal right to use all payment method(s) represented by any such Payment Information. You may need to provide additional information to verify your identity before completing your transaction (such information is included in the Payment Information definition). We reserve the right to use the Payment Information you provide us in connection with any payments to provide better service to you should you wish to use our service again in the future and to protect us from fraud and other losses. Completion of a payment transaction is contingent upon:</p>
<ol>
<li>you providing complete personal, account, transaction and any other information needed,</li>
<li>authorisation of the payment by your credit or debit card company, and</li>
<li>acceptance of your payment.</li>
</ol>
<p>
<span style="text-decoration:underline;">Payment and Refund Processing.</span>
Payments and refunds facilitated by the Services may be processed by a third-party payment processor (“<strong>Payment Processor</strong>”). You may be required to create an account with such a Payment Processor, and by doing so, you are agreeing to comply with the Payment Processor’s terms of service. You may need to provide additional information to verify your identity, business and tax information, and bank information when you register with the Payment Processor. If, and to the extent, we accept, hold or transmit funds in connection with your purchase, you agree that we do so, on your behalf, as your limited agent, and you hereby appoint SongBits as your limited agent solely to accept payments from, and refund payments to, Service Recipients on your behalf. In accepting appointment as your limited agent, we assume no liability for any of your acts or omissions.</p>
<p>
<span style="text-decoration:underline;">Chargebacks and Payment Holdback</span>. We reserve the right to offset future payments, including any streaming royalties, to you (“<strong>Payment Holdbacks</strong>”) in certain cases including without limitation: (i) where your SongBits Account is subject to excessive chargebacks resulting in additional costs or fees from the Payment Processor; (ii) where the Payment Processor withholds a percentage of a payment as a reserve, for example, as a result of actual or suspected fraudulent activity; or (iii) in certain cases where we decide, in our sole discretion, that it is prudent or necessary to reverse a transaction. We further reserve the right to implement Payment Holdbacks to you if payments are deemed suspicious or fraudulent at our sole discretion. We will notify you that you are subject to a Payment Holdback and may require you to provide supporting documentation or information related to the relevant payment(s) and corresponding purchase(s). If you do not provide such supporting evidence within the requested timeframe, we reserve the right to refund the payment and transfer any available funds from your wallet to your identified bank account or otherwise take any action we feel is reasonably necessary to comply with our compliance standards, including those imposed by any relevant financial institution, our Payment Processor, or the credit card network rules, as well as any applicable law.</p>
<p>Failure to Process Payments, Refunds or Chargebacks. You are solely responsible for the information you provide concerning payments and refunds processed via the Services. We will not be liable for failure to complete any payment or refund from or to your SongBits Account if you provide inaccurate or incomplete information regarding the payment or refund. Subject to applicable law, we reserve the right to send any delinquent, past due, or any other SongBits Account that is in default to collections agencies to recover such past due amount</p>
<p>
<strong>Costs and Taxes</strong>. You are solely responsible for all costs incurred in using the Services and determining, collecting, reporting and paying all applicable Taxes. As used herein, “<strong>Taxes</strong>” means the taxes, duties, levies, tariffs, and other governmental charges you may require by law to collect and remit to governmental agencies or other withholding and personal or corporate income taxes.</p>
<p>
<strong>Regulatory and Compliance Suspensions or Terminations.
</strong>We may suspend or terminate your access to the Services at any time in connection with any transaction as required by applicable law or any governmental authority or if we, in our sole and reasonable discretion, determine you are violating these Terms or the terms of any third-party service provider. Such suspension or termination shall not constitute a breach of these Terms by SongBits. Under our anti-money laundering, anti-terrorism, anti-fraud, and other compliance policies and practices, we may impose reasonable limitations and controls on the ability of you or any beneficiary to utilise the Services. Such limitations may include where good cause exists, rejecting transaction requests, freezing funds, or restricting you from using the Services.</p>
<p>
<strong>Your Content; SongBits.</strong>
</p>
<p>
<strong>Posting Content</strong>. Our Services may allow you to store or share content such as text (in posts or communications with others), files, documents, graphics, images, music, software, audio and video. Anything (other than Feedback) you post or otherwise make available through the Services is called “<strong>User Content</strong>”. SongBits does not claim any ownership rights in any User Content and nothing in these Terms will be deemed to restrict any rights you may have to your User Content.</p>
<p>
<strong>Ownership</strong>. As an Artist, subject to and the rights of a Fan ownership of a SongBit and the grant of rights described below, you retain ownership of any User Content you submit, post, or display using the Service. SongBits does not claim ownership rights in any User Content, and nothing in these Terms will be deemed to restrict any rights you may have to your User Content.</p>
<p>
<strong>Permissions to Your User Content</strong>. By making any User Content available through the Services, you hereby grant to SongBits a non-exclusive, transferable, worldwide, royalty-free license, with the right to sublicense, use, copy, modify, create derivative works based upon, distribute, publicly display, and publicly perform your User Content in connection with operating and providing the Services.</p>
<p>
<strong>SongBits Terms</strong>. Any SongBits that you purchase are governed entirely by this contract. SongBits may be subject to additional terms directly between Artists and Fans, namely: (i) in any associated contract or (ii) as purchasers and sellers may agree otherwise (e.g., with respect to terms or benefits associated with a given song release ((i) and (ii), collectively, “<strong>Purchase Terms</strong>”). SongBits is not a party to any Fan Terms, which are solely between the Artist and the Fan. The Artist and Fan are entirely responsible for communicating, promulgating, agreeing to, and enforcing Fan Terms.</p>
<p>
<strong>SongBits Ownership</strong>. When you purchase a SongBit through the Service, you own all personal property rights to that SongBit (i.e., the right to sell or otherwise dispose of that SongBit). Such rights, however, do not include the ownership of the intellectual property rights in the User Content comprising the SongBits (“<strong>SongBits</strong>
<strong>Content</strong>”). Unless specified otherwise in Fan Terms, the Fan will have a worldwide, non-exclusive, non-transferable, royalty-free license to use, copy, and display the SongBits Content associated with the SongBit), solely for the following purposes: (i) for your own personal, non-commercial use; (ii) to attempt to sell or otherwise dispose of the SongBits consistent with the ownership of it; and (iii) as part of a third party offering compatible with the purchased SongBits in the normal course of the permitted end-use of such offering.</p>
<p>
<strong>Artist Obligations</strong>. If you are an Artist, you grant SongBits the right to use your name and image for marketing or promotional purposes, including but not limited to publicity about your SongBit release. If you are an Artist, you also grant any Fan the rights described in Section 6(e) above. As an Artist, you understand and agree that: (i) you are responsible for the accuracy and content of your SongBits release; (ii) any SongBits release that violates any of SongBits’ policies or applicable law may be obfuscated or deleted by SongBits, in its sole discretion; (iii) you will not infringe or otherwise violate the intellectual property and other rights of others; and (iv) you will not coordinate with other Artist to engage in any activities that detract from the Service being an open and fair marketplace, such as manipulating pricing.</p>
<p>
<strong>Authority.
</strong>You represent and warrant that you have, or have obtained, all rights, licenses, consents, permissions, power and/or authority necessary to grant the rights granted herein for any User Content that you submit, post or display on or through the Service, including but not limited to SongBits Content. You agree that such User Content will not contain material subject to copyright or other proprietary rights unless you have necessary permission or are otherwise legally entitled to post the material and to grant SongBits the license described above.</p>
<p>
<strong>Your Responsibility for User Content</strong>. You are solely responsible for all your User Content. You represent and warrant that you have (and will have) all rights that are necessary to grant us the license rights in your User Content under these Terms. You represent and warrant that neither your User Content, nor your use and provision of your User Content to be made available through the Services, nor any use of your User Content by SongBits on or through the Services will infringe, misappropriate or violate a third party’s intellectual property rights, or rights of publicity or privacy, or result in the violation of any applicable law or regulation.</p>
<p>
<strong>Removal of User Content</strong>. You should know that in certain instances, some of your User Content (such as posts or comments you make) may not be completely removed, and copies of your User Content may continue to exist on the Service. To the maximum extent permitted by law, we are not responsible or liable for the removal or deletion of (or the failure to remove or delete) any User Content.</p>
<p>
<strong>SongBits’ Intellectual Property</strong>. We may make available through the Services content subject to intellectual property rights. We retain all rights to that content.</p>
<p>
<strong>Feedback.
</strong>We appreciate feedback, comments, ideas, proposals and suggestions for improvements to the Services (“<strong>Feedback</strong>”). If you choose to submit Feedback, you agree that we are free to use it (and permit others to use it) without any restriction or compensation to you.</p>
<p>
<strong>General Prohibitions</strong>. You agree not to do any of the following:</p>
<ol>
<li>Post, upload, publish, submit or transmit any User Content that: (i)infringes, misappropriates or violates a third party’s patent, copyright, trademark, trade secret, moral rights or other intellectual property rights, or rights of publicity or privacy; (ii) violates, or encourages any conduct that would violate, any applicable law or regulation or would give rise to civil liability; (iii)is fraudulent, false, misleading or deceptive; (iv) is defamatory, obscene, pornographic, vulgar or offensive; (v)promotes discrimination, bigotry, racism, hatred, harassment or harm against any individual or group; (vi) is violent or threatening or promotes violence or actions that are threatening to any person or entity; or (vii) promotes illegal or harmful activities or substances;</li>
<li>Use, display, mirror or frame the Services or any individual element within the Services, SongBits’ name, any SongBits trademark, logo or other proprietary information, or the layout and design of any page or form contained on a page, without SongBits’ express written consent;</li>
<li>Attempt to access or search the Services or download content from the Services using any engine, software, tool, agent, device or mechanism (including spiders, robots, crawlers, data mining tools or the like) other than the software and/or search agents provided by SongBits or other generally available third-party web browsers;</li>
<li>Use the Services, or any portion thereof, for any commercial purpose or for the benefit of any third party or in any manner not permitted by these Terms;</li>
<li>Attempt to decipher, decompile, disassemble or reverse engineer any of the software used to provide the Services;</li>
<li>Collect or store any personally identifiable information from the Services from other users of the Services without their express permission;</li>
<li>Impersonate or misrepresent your affiliation with any person or entity;</li>
<li>Violate any applicable law or regulation; or</li>
<li>Encourage or enable any other individual to do any of the foregoing.</li>
</ol>
<p>
<strong>General Enforcement Rights</strong>.</p>
<p>
<strong>Unlawful Content</strong>. Assets, listings, smart contracts, collections, and content (including User Content) that SongBits deems inappropriate, disruptive, or illegal are prohibited on SongBits. SongBits reserves the right to determine the appropriateness of any content and listings on its site and remove any such content or listings at any time. If you create or offer a listing, collection, or User Content in violation of these policies, we will take corrective actions, as appropriate, including but not limited to removing the listing, collection, or User Content, deleting your SongBits Account, and permanently withholding any applicable referral payments or developer revenue sharing fees that you may be eligible for. SongBits cannot destroy or impound your assets or contract, but we reserve the right to destroy inappropriate metadata stored on our servers.</p>
<p>
<strong>Illegal Content</strong>. Assets, listings, contracts, collections, and User Content that include metadata, or which have a primary or substantial purpose in a game or application, that violates international intellectual property laws, promotes suicide or self-harm, incites hate or violence against others, degrades or doxes another individual, depicts minors in sexually suggestive situations, or is otherwise illegal are prohibited on SongBits and will be removed.</p>
<p>
<strong>Content Used for Unlawful Purposes</strong>. Assets, listings, contracts, collections, and User Content created or used primarily or substantially to raise funds for known terrorist organisations are prohibited on SongBits and will be removed.</p>
<p>
<strong>Stolen Content</strong>. The sale of stolen assets, assets taken without authorisation, and otherwise illegally obtained assets on SongBits is prohibited. If you have reason to believe that an asset listed on SongBits was illegally obtained, please contact us immediately. Listing illegally obtained assets may result in your listings being cancelled, your assets being hidden, or your Account being suspended.</p>
<p>
<strong>Reporting Content</strong>. If you become aware of the creation, listing, or buying of assets violating any of the terms specified in this Section, please contact us at notices@songbits.com to report it. User Content creators bear special responsibility for informing SongBits of the existence of their collections, contracts, and assets that violate these terms.</p>
<p>
<strong>No Obligation</strong>. SongBits is not obligated to monitor access to or use the Services or review or edit any content. However, we have the right to do so to operate the Services, ensure compliance with these Terms, and comply with applicable law or other legal requirements. We reserve the right but are not obligated to remove or disable access to any content, including User Content, at any time and without notice, including, but not limited to, if we, at our sole discretion, consider it objectionable or violating these Terms. We have the right to investigate violations of these Terms or conduct that affects the Services. We may also consult and cooperate with law enforcement authorities to prosecute users who violate the law.</p>
<p>
<strong>Copyright Policy.
</strong>SongBits respects copyright law and expects its users to do the same. In appropriate circumstances, it is SongBits’ policy to terminate account holders who repeatedly infringe or are believed to be repeatedly infringing the rights of copyright holders.</p>
<p>
<strong>SongBits’ Intellectual Property</strong>. Unless otherwise indicated in writing by us, the Service and all content and other materials contained therein, including, without limitation, the SongBits logo and all designs, text, graphics, pictures, information, data, software, sound files, other files and the selection and arrangement thereof (collectively, “<strong>SongBits Content</strong>”) are the proprietary property of SongBits or our affiliates, licensors or users, as applicable. You may not use any SongBits Content or link to the Service without our express written permission. Without our written consent, you may not use framing techniques to enclose any SongBits Content.</p>
<p>SongBits and its licensors exclusively own all rights, titles and interests in and to the Service, including all associated intellectual property rights therein. You acknowledge that the Service is protected by copyright, trademark, and other laws of the United Kingdom. You agree not to remove, alter or obscure any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying the Service.</p>
<p>References to any other products, services, processes or other information by name, trademark, manufacturer, supplier or otherwise on the Service does not constitute or imply endorsement, sponsorship or recommendation by SongBits.</p>
<p>
<strong>Links to Third-Party Websites or Resources.
</strong>The Services may allow you to access third-party websites or other resources. We provide access only as a convenience and are not responsible for the content, products or services on or available from those resources or links on such websites. You acknowledge sole responsibility for and assume all risk from using any third-party resources.</p>
<p>
<strong>Termination.
</strong>We may suspend or terminate your access to and use of the Services, including suspending access to or terminating your account, at our sole discretion, at any time and without notice to you. You may cancel your account at any time by sending us an email at goodbye@songbits.com.&nbsp;</p>
<p>
<strong>Disclaimers</strong>.</p>
<ol>
<li>EXCEPT AS EXPRESSLY PROVIDED TO THE CONTRARY IN WRITING BY SONGBITS, THE SERVICE, SONGBITS CONTENT CONTAINED THEREIN, AND ANY OTHER ASSETS LISTED THEREIN ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED. SONGBITS (AND ITS SUPPLIERS) MAKE NO WARRANTY THAT THE SERVICE: (I) WILL MEET YOUR REQUIREMENTS; (II) WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS; OR (III) WILL BE ACCURATE, RELIABLE, COMPLETE, LEGAL, OR SAFE. SONGBITS DISCLAIMS ALL OTHER WARRANTIES OR CONDITIONS, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, INCLUDING ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, AS TO THE SERVICE, SONGBITS CONTENT CONTAINED THEREIN AND ANY SONGBITS OR OTHER ASSETS LISTED THEREIN.</li>
<li>SONGBITS DOES NOT REPRESENT OR WARRANT THAT SONGBITS CONTENT ON THE SERVICE IS ACCURATE, COMPLETE, RELIABLE, CURRENT OR ERROR-FREE. WE WILL NOT BE LIABLE FOR ANY KIND OF LOSS FOR ANY ACTION TAKEN IN RELIANCE ON MATERIAL OR INFORMATION CONTAINED IN THE SERVICE. WHILE SONGBITS ATTEMPTS TO MAKE YOUR ACCESS TO AND USE OF THE SERVICE AND SONGBITS CONTENT SAFE, SONGBITS CANNOT AND DOES NOT REPRESENT OR WARRANT THAT THE SERVICE, SONGBITS CONTENT, ANY SONGBITS OR OTHER ASSETS LISTED THEREIN, OR OUR SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. WE CANNOT GUARANTEE THE SECURITY OF ANY DATA THAT YOU DISCLOSE ONLINE. YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING INFORMATION AND DEALING ONLINE OVER THE INTERNET AND WILL NOT HOLD US RESPONSIBLE FOR ANY BREACH OF SECURITY UNLESS IT IS DUE TO OUR GROSS NEGLIGENCE.</li>
<li>WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSS AND TAKE NO RESPONSIBILITY FOR, AND WILL NOT BE LIABLE TO YOU FOR, ANY USE OF SONGBITS, INCLUDING BUT NOT LIMITED TO ANY LOSSES, DAMAGES OR CLAIMS ARISING FROM: (I) USER ERROR SUCH AS FORGOTTEN PASSWORDS OR MISTYPED ADDRESSES; (II) SERVER FAILURE OR DATA LOSS; (III) CORRUPTED FILES; (IV) UNAUTHORISEDACCESS TO APPLICATIONS; OR (V) ANY UNAUTHORISEDTHIRD PARTY ACTIVITIES, INCLUDING WITHOUT LIMITATION THE USE OF VIRUSES, PHISHING, BRUTEFORCING OR OTHER MEANS OF ATTACK AGAINST THE SERVICE OR ASSETS.</li>
</ol>
<p>Nothing in these Terms shall exclude or limit either party's liability for fraud, violation of laws, or any other activity that cannot be limited or excluded by legitimate means.</p>
<p>
<strong>Assumption of Risk.
</strong>You accept and acknowledge:</p>
<ol>
<li>You are solely responsible for determining what, if any, taxes apply to your transactions through the Service. Neither SongBits nor any other SongBits Party is responsible for determining the taxes that apply to such transactions.</li>
<li>The Service may rely on third-party platforms to perform transactions with respect to any assets. If we are unable to maintain a good relationship with such platform providers; if the terms and conditions or pricing of such platform providers change; if we violate or cannot comply with the terms and conditions of such platforms; or if any of such platforms loses market share or falls out of favour or is unavailable for a prolonged period of time, access to and use of the Service will suffer.</li>
<li>There are risks associated with purchasing user-generated content. SongBits reserves the right to hide collections and assets affected by any of these issues or other issues.&nbsp;</li>
</ol>
<p>
<strong>Indemnity.
</strong>To the fullest extent permitted by applicable law, you agree to indemnify, defend and hold harmless SongBits, and our respective past, present and future employees, officers, directors, contractors, consultants, equity holders, suppliers, vendors, service providers, parent companies, subsidiaries, affiliates, agents, representatives, predecessors, successors and assigns (individually and collectively, the “<strong>SongBits Parties</strong>”), from and against all actual or alleged third party claims, damages, awards, judgments, losses, liabilities, obligations, penalties, interest, fees, expenses (including, without limitation, attorneys’ fees and expenses) and costs (including, without limitation, court costs, costs of settlement and costs of pursuing indemnification and insurance), of every kind and nature whatsoever, whether known or unknown, foreseen or unforeseen, matured or unmatured, or suspected or unsuspected, in law or equity, whether in tort, contract or otherwise (collectively, “<strong>Claims</strong>”), including, but not limited to, damages to property or personal injury, that are caused by, arise out of or are related to: (i) your use or misuse of the Service or SongBits Content or User Content; (ii) your User Content or Feedback; (iii) your violation of these Terms; (iv) your violation of the rights of a third party or of applicable law. You agree to promptly notify SongBits of any Claims and cooperate with the SongBits Parties in defending such Claims. You further agree that the SongBits Parties shall have control of the defence or settlement of any third-party Claims. THIS INDEMNITY IS IN ADDITION TO, AND NOT INSTEAD OF, ANY OTHER INDEMNITIES OUTLINED IN A WRITTEN AGREEMENT BETWEEN YOU AND SONGBITS.</p>
<p>
<strong>Limitation of Liability.</strong>
</p>
<p>TO THE MAXIMUM EXTENT PERMITTED BY LAW, NEITHER SONGBITS NOR ITS SERVICE PROVIDERS INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE SERVICES WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, OR DAMAGES FOR LOST PROFITS, LOST REVENUES, LOST SAVINGS, LOST BUSINESS OPPORTUNITY, LOSS OF DATA OR GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE OR THE COST OF SUBSTITUTE SERVICES OF ANY KIND ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT SONGBITS OR ITS SERVICE PROVIDERS HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.</p>
<p>TO THE MAXIMUM EXTENT PERMITTED BY THE LAW OF THE APPLICABLE JURISDICTION, IN NO EVENT WILL SONGBITS’ TOTAL LIABILITY ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SERVICES EXCEED THE AMOUNTS YOU HAVE PAID OR ARE PAYABLE BY YOU TO SONGBITS FOR USE OF THE SERVICES OR ONE HUNDRED POUNDS (£100), IF YOU HAVE NOT HAD ANY PAYMENT OBLIGATIONS TO SONGBITS, AS APPLICABLE.</p>
<p>THE EXCLUSIONS AND LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN SONGBITS AND YOU.</p>
<p>
<strong>Governing Law and Forum Choice.
</strong>These Terms and any action related thereto will be governed by the laws of the United Kingdom.&nbsp;</p>
<p>“<strong>Dispute Resolution</strong>,” the exclusive jurisdiction for all Disputes (defined below) that you SongBits, are not required to arbitrate, will be in the United Kingdom, and you and SongBits each waive any objection to jurisdiction and venue in such courts.</p>
<p>
<strong>Dispute Resolution.</strong>
</p>
<ol>
<li>
  <strong>Mandatory Arbitration of Disputes</strong>. We each agree that any dispute, claim or controversy arising out of or relating to these Terms or the breach, termination, enforcement, interpretation or validity thereof or the use of the Services (collectively, “<strong>Disputes</strong>”) will be resolved
  <strong>solely by binding, individual arbitration and not in a class, representative or consolidated action or proceeding</strong>. You and SongBits agree that UK law governs the interpretation and enforcement of these terms and that you and SongBits each waive the right to a trial by jury or to participate in a class action. This arbitration provision shall survive termination of these Terms.</li>
<li>
  <strong>Exceptions</strong>. As limited exceptions to Section 1 above: (i) we both may seek to resolve a Dispute in small claims court if it qualifies; and (ii) we each retain the right to seek injunctive or other equitable relief from a court to prevent (or enjoin) the infringement or misappropriation of our intellectual property rights.</li>
<li>
  <strong>Conducting Arbitration and Arbitration Rules</strong>. The arbitration will be conducted by JAMS under its JAMS Comprehensive Arbitration Rules and Procedures (the “<strong>JAMS Rules</strong>”) then in effect, except as modified by these Terms. The JAMS Rules are available at https://www.jamsadr.com/. A party who wishes to start arbitration must submit a written Demand for Arbitration to JAMS and give notice to the other party as specified in the JAMS Rules. JAMS provides a form Demand for Arbitration at https://www.jamsadr.com/. Any arbitration hearings will take place where you live, unless we both agree to a different location, but will be conducted remotely to the extent permitted by the JAMS Rules. The parties agree that the arbitrator shall have exclusive authority to decide all issues relating to the interpretation, applicability, enforceability and scope of this arbitration agreement.</li>
<li>
  <strong>Arbitration Costs</strong>. Payment of all filing, administration and arbitrator fees will be governed by the JAMS Rules, and we won’t seek to recover the administration and arbitrator fees we are responsible for paying unless the arbitrator finds your Dispute frivolous. If we prevail in arbitration, we’ll pay all our legal fees and costs and won’t seek to recover them from you. If you prevail in arbitration, you will be entitled to legal fees and expenses award to the extent provided under applicable law.</li>
<li>
  <strong>Injunctive and Declaratory Relief</strong>. Except as provided in Section 2 above, the arbitrator shall determine all issues of liability on the merits of any claim asserted by either party and may award declaratory or injunctive relief only in favour of the individual party seeking relief and only to the extent necessary to provide relief warranted by that party’s individual claim. To the extent that you or we prevail on a claim and seek public injunctive relief (that is, injunctive relief that has the primary purpose and effect of prohibiting unlawful acts that threaten future injury to the public), the entitlement to and extent of such relief must be litigated in a civil court of competent jurisdiction and not in arbitration. The parties agree that litigation of any issues of public injunctive relief shall be stayed pending the outcome of the merits of any individual claims in arbitration.</li>
</ol>
<p>
<strong>Class Action Waiver</strong>.</p>
<p>
<strong>YOU AND SONGBITS AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.
</strong>Further, if the parties’ Dispute is resolved through arbitration, the arbitrator may not consolidate another person’s claims with your claims and may not otherwise preside over any form of a representative or class proceeding. If this specific provision is found to be unenforceable, then the entirety of this Dispute Resolution section shall be null and void.</p>
<p>
<strong>Severability</strong>. Except for any of the provisions of these Terms (“<strong>Class Action Waiver</strong>”), if an arbitrator or court of competent jurisdiction decides that any part of these Terms is invalid or unenforceable, the other parts will still apply.</p>
<p>
<strong>General Terms.</strong>
</p>
<p>
<strong>Reservation of Rights</strong>. SongBits and its licensors exclusively own all rights, titles and interests in and to the Services, including all associated intellectual property rights. You acknowledge that the Services are protected by copyright, trademark, and other laws of the United Kingdom and foreign countries. You agree not to remove, alter or obscure any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying the Services.</p>
<p>
<strong>Entire Agreement</strong>. These Terms constitute the entire and exclusive understanding and agreement between SongBits and you regarding the Services, and these Terms supersede and replace all prior oral or written understandings or agreements between SongBits and you regarding the Services. If any provision of these Terms is held invalid or unenforceable by an arbitrator or a court of competent jurisdiction, that provision will be enforced to the maximum extent permissible, and the other provisions will remain in full force and effect. You may not assign or transfer these Terms, by operation of law or otherwise, without SongBits’ prior written consent. Any attempt by you to assign or transfer these Terms without such consent will be null. SongBits may freely assign or transfer these Terms without restriction. Subject to the foregoing, these Terms will bind and inure to the benefit of the parties, their successors and permitted assigns.</p>
<p>
<strong>Notices</strong>. Any notices or other communications provided by SongBits under these Terms will be given (i) via email or (ii) by posting to the Services. For notices made by email, the date of receipt will be deemed the date on which such notice is transmitted.</p>
<p>
<strong>Waiver of Rights</strong>. SongBits’ failure to enforce any right or provision of these Terms will not be considered a waiver of such right or provision. The waiver of any such right or provision will be effective only if in writing and signed by a duly authorised representative of SongBits. Except as expressly outlined in these Terms, the exercise by either party of any of its remedies will be without prejudice to its other remedies under these Terms or otherwise.</p>
<p>
<strong>Contact Information.
</strong>If you have any questions about these Terms or the Services, please contact SongBits at notices@songbits.com</p>
<p>SongBits Limited. Level 3, 207 Regent Street, London W1B 3HH</p>`
